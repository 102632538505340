const services = [
    {
      id: 1,
      title: 'Digital Solutions',
      path:'/services',
      text:
        "We are committed to providing innovative digital solutions that can help businesses succeed in the digital age. Contact us, share your vision and find the perfect way to make it happen. Learn about how our solutions can help your business grow.",
    },
    {
      id: 2,
      title: 'IT Consultancy',
      path:'/services',
      text:
        "All software & IT majors benefit from our core solutions. We cater to the needs of diverse industry across verticals. We develop from Web Apps to Saas Solutions & Micro Services to FINTECH Solutions as well as Custom Desgined Management Systems meeting your needs to taking your business to the CLOUD. ",
    },
    {
      id: 3,
      title: 'Solution Architecture',
      path:'/services',
      text:
        "We have a team of experienced software architects who can help you design and implement a software architecture that meets your business needs. Choosing the right architecture style & ensuring that the system meets its Functional and Non-Functional requirements",
    },
    {
      id: 4,
      title: 'Security Encryption ',
      path:'/services',
      text:
        "We offer top tier encryption services to help protect your sensitive data. We can help you choose the right encryption method for your data and implement it to ensure the security and integrity of your data.",
    },
    {
      id: 5,
      title: 'Software Testing',
      path:'/services',
      text:
        "Quality Testing of your product before release. We adhere to the strictest guidelines & testing standards. Our experienced testers use a variety of testing techniques & tools to identify, report defects, track issues and ensure that your software system meets its specifications.",
    }
    
  ];
  
  export default services;