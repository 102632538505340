import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import NavBar from './components/NavBar/NavBar';
import ReactGA from "react-ga";
import Services from './pages/Services/Services';
import Portfolio from './pages/Portfolio/Portfolio';
import Home from './pages/Home/Home';
import ContactPage from './pages/Contact/Contact';
// import AboutUs from './pages/AboutUs/AboutUs';
const MEASUREMENT_ID = "G-249CC9X5GE"; 
ReactGA.initialize(MEASUREMENT_ID);
function App() {
  return (
    <>
      <Router>
        <NavBar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" exact component={Home} />
          <Route path="/services" component={Services} />
          <Route path="/projects" component={Portfolio} />
          {/* <Route path="/aboutus" component={AboutUs} /> */}
          <Route path="/contact" component={ContactPage} />
          <Route path="/whatsapp" component={() => {
  window.open('https://api.whatsapp.com/send?phone=03044610883', '_blank');
  return null;
}} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
