import React from "react";
import ServicesPageHeroSection from "../../components/ServicesPageHeroSection";
// import ThreeAngledDivs from "../../components/ThreeAngledDivs";
import WhyChooseUsSlider from "../../components/WhyChooseUsSlider";
import ServicesCards from "../../components/ServicesCards";
import ContactUs2 from "../../components/ContactUs2";
import Footer from "../../components/Footer";


const Services = () => {
  return (
    <>
      <ServicesPageHeroSection/>
      <WhyChooseUsSlider/>
      <ServicesCards/>
      <ContactUs2/>
      <Footer/>
      {/* <ThreeAngledDivs/> */}
      
    </>
  );
};

export default Services;
