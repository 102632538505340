import React from "react";
import "../pages/Portfolio/Portfolio.css";
import "../App.css";
import portfolio_data from "./portfolio_data";

const PortfolioMain = () => {

  const renderPortfolioBoxes = () => {
    return portfolio_data.map((item, index) => (
      <div className="portfolio-box" key={index}>
        <a
          className="portfolio-box-link"
          href={item.path}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="portfolio-box-info">
            <h2 className="portfolio-box-info-title">{item.title}</h2>
            <div className="image-container">
              {item.images.map((image, imageIndex) => (
                <img
                  key={imageIndex}
                  src={image}
                  alt={`${item.title} - Image ${imageIndex + 1}`}
                  className="moving-image"
                />
              ))}
            </div>
          </div>
        </a>
      </div>
    ));
  };

  return (
    <div className="portfolio-main" style={{ paddingBottom: "4%" }}>
      <section className="portfolio-heading">
        <h3>Our Projects</h3>
        <hr className="portfolio-line-underheading" />
      </section>
      <section className="portfolio-container">{renderPortfolioBoxes()}</section>
    </div>
  );
};

export default PortfolioMain;
