import React, { useState, useEffect } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import data from "./testimonials_data";
import testimonialBG from "../assets/testimonial-bg.jpg";
const Testimonials = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = data.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, data]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 5000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <section className="testimonials-section">
      <img className="testimonials-section-bg" src={testimonialBG} alt="" />
      <div className="title">
        <h3 style={{ marginTop: "0px", paddingTop: "4%" }}></h3>
        <h1>
          What do our customers say <br /> about us?
        </h1>
        <hr className="testimonial-line-underheading" />
      </div>
      <div className="section-center">
        {data.map((person, personIndex) => {
          const { id, image, name, productUrl, product, review } = person;

          let position = "nextSlide";
          if (personIndex === index) {
            position = "activeSlide";
          }
          if (
            personIndex === index - 1 ||
            (index === 0 && personIndex === data.length - 1)
          ) {
            position = "lastSlide";
          }

          return (
            <article className={position} key={id}>
              <img src={image} alt={name} className="person-img" />
              <h4>{name}</h4>
              <a href={productUrl} target="_blank" rel="noopener noreferrer">
                <h6>{product}</h6>
              </a>
              <p className="text">{review}</p>
            </article>
          );
        })}
        <button className="prev" onClick={() => setIndex(index - 1)}>
          <FiChevronLeft />
        </button>
        <button className="next" onClick={() => setIndex(index + 1)}>
          <FiChevronRight />
        </button>
      </div>
    </section>
  );
};

export default Testimonials;
