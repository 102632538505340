import React from "react";

const WorkFlow = () => {
  return (
    <div className="workflow-main">
      <h1>
        How STech assist your <br />
        startup business
      </h1>
      <p>
        All software & IT majors benefit from our core solutions. We cater the{" "}
        <br className="workflow-br" />
        needs of diverse industriesacross verticals. We are a team of souls.
      </p>

      <div className="workflow-row">
        <div className="workflow-card">
          <div className="lines line-1">
            <div className="circle circle-1">
              <p>01</p>
            </div>
          </div>
          <h3>Idea & Concept making</h3>
          <p className="workflow-p">
            conceptualizing new ideas and thoughts for start-up software firmsr.
          </p>
        </div>
        <div className="workflow-card">
          <div className="lines">
            <div className="circle">
              <p>02</p>
            </div>
          </div>
          <h3>Discussion & Implement</h3>
          <p className="workflow-p">
            Implementing the best customized IT solutions for new web
            enterprises.
          </p>
        </div>
        <div className="workflow-card">
          <div className="lines">
            <div className="circle">
              <p>03</p>
            </div>
          </div>
          <h3>Test & Report</h3>
          <p className="workflow-p">
            Discussing cost-effictive means of product & services integration
            Apps.
          </p>
        </div>
        <div className="workflow-card">
          <div className="lines line-4">
            <div className="circle">
              <p>04</p>
            </div>
          </div>
          <h3>Execute & Support</h3>
          <p className="workflow-p">
            Executing a robust IT road-map for future business models and
            companies.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WorkFlow;
