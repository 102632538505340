import React from "react";
import card1 from "../assets/CardsAnimation1.jfif";
import card2 from "../assets/CardsAnimation22.jfif";
import card3 from "../assets/CardsAnimation3.jfif";
import card4 from "../assets/CardsAnimation4.jfif";
import card5 from "../assets/CardsAnimation5.jfif";
import card6 from "../assets/download (6).jfif";

const CardsAnimation = () => {
  return (
    <>
      <div className="CardsAnimation-main">
        <div className="wrapper">
          <div className="cards">
            <figure className="card ht-sm">
              <h3>IT Managment</h3>
              <img src={card1} alt="" />

              <figcaption>We Provide best IT Management</figcaption>
            </figure>

            <figure className="card ht-lg" style={{ marginTop: "100px" }}>
              <h3>Cyber Security</h3>

              <img src={card2} alt="" />

              <figcaption>We Provide Cyber Security</figcaption>
            </figure>

            <figure className="card ht-sm">
              <h3>Cloud Computing</h3>

              <img src={card3} alt="" />

              <figcaption>We Provide Cloud Computing</figcaption>
            </figure>
          </div>
        </div>
        <div className="wrapper" style={{ paddingBottom: "5%" }}>
          <div className="cards">
            <figure className="card ht-lg" style={{ marginTop: "-100px" }}>
              <h3>Software Development</h3>

              <img src={card4} alt="" />

              <figcaption>We Provide Software Development</figcaption>
            </figure>

            <figure className="card ht-sm">
              <h3>Backup & Recovery</h3>

              <img src={card5} alt="" />

              <figcaption>We Provide Backup & Recovery</figcaption>
            </figure>

            <figure className="card ht-lg" style={{ marginTop: "-100px" }}>
              <h3>Social Media App</h3>

              <img src={card6} alt="" />

              <figcaption>We Provide Social Media App</figcaption>
            </figure>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardsAnimation;
