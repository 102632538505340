import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import '../App.css'
const Particle = () => {
  const particlesInit = async (engine) => {
    // console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  };

  const particlesLoaded = (container) => {
    // console.log(container);
  };

  return (
    <Particles
    className="particles-transition"
    id="tsparticles"
    init={particlesInit}
    loaded={particlesLoaded}
    options={{
        background: {
          color: {//"#282c34",
            value: "none",
          },
        },
       
        fpsLimit: 30,
        interactivity: {
          // detectsOn: "canvas",
          events: {
            onHover: {
              enable: true,
              mode: "grab",
            },
            resize: false,
          },
        },
        particles: {
          color: {
            value: "#ffffff",
          },
          links: {
            color: "#ffffff",
            distance: 200,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            directions: "none",
            enable: true,
            outModes: {
              default: "bounce",
            },
            random: false,
            speed: 3,
            straight: false,
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 70,
          },
          opacity: {
            value: 0.5,
          },
          shape: {
            type: "round",
          },
          size: {
            value: { min: 5, max: 5 },
          },
        },
        detectRetina: true,
      }}
    />
  );
};

export default Particle;
