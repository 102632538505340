import React from "react";
import { Link } from "react-router-dom";
import contactusBG from "../assets/contactus-bg.jpg";

import { FaWhatsappSquare } from "react-icons/fa";

const ContactUs = () => {
  const handleClick = () => {
    window.open("https://api.whatsapp.com/send?phone=03351757297", "_blank");
  };
  return (
    <div className="contactus">
      <img className="contactus-section-bg" src={contactusBG} alt="" />
      <div className="contactus-row">
        <div className="contactus-col-1">
          <div className="contactus-box">
            Hire a
            <div className="contactus-word">
              <span>Digital Marketer</span>
              <span>Software Consultant</span>
              <span>Web Developer</span>
              <span>Graphic Designer</span>
            </div>
          </div>
          <hr className="contactus-line-underheading" />
          <h3>
            Uncover the best professional team for the ultimate business
            success.
          </h3>
        </div>
        <div className="contactus-col-2">
        <button  className="button2" onClick={handleClick}>
      
        <FaWhatsappSquare
         style={{color:'green',fontSize:'48px',borderRadius:'200%'}}
         />
      
        </button>
          <Link to="/contact" className="button">
            Contact us @
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
