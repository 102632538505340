import * as React from "react";
import "../pages/Home/Home.css";
import "../App.css";
import services_data from "./services_data";
import { GiArchiveResearch } from "react-icons/gi";
import { SiGoogleanalytics } from "react-icons/si";
import { FaServicestack } from "react-icons/fa";
import { FaTools } from "react-icons/fa";
import { SiOpencollective } from "react-icons/si";

import bghomeservice from "../assets/bg-home-service.jpg";

import { Link } from "react-router-dom";

const Service = () => {
  return (
    <div className="services-main" style={{ zIndex: 1, paddingBottom: "4%" }}>
      <img src={bghomeservice} alt="" />
      <section className="service-heading">
        <h3>WHY CHOOSE US ?</h3>
        <h1>Because You Deserve Exceptional Quality and Intelligence</h1>
        <hr className="service-line-underheading" />
      </section>
      <section className="service-container">
        <div className="service-box">
          <Link className="service-box-link" to={services_data[0].path}>
            <div className="service-box-info">
              <h2 className="service-icon">
                <GiArchiveResearch />
              </h2>
              <h2 className="service-box-info-title">
                {services_data[0].title}
              </h2>
              <p className="service-box-text">{services_data[0].text}</p>
            </div>
          </Link>
        </div>
        <div className="service-box">
          <Link className="service-box-link" to={services_data[1].path}>
            <div className="service-box-info">
              <h2 className="service-icon">
                <SiGoogleanalytics />
              </h2>
              <h2 className="service-box-info-title">
                {services_data[1].title}
              </h2>
              <p className="service-box-text">{services_data[1].text}</p>
            </div>
          </Link>
        </div>
        <div className="service-box">
          <Link className="service-box-link" to={services_data[2].path}>
            <div className="service-box-info">
              <h2 className="service-icon">
                <FaServicestack />
              </h2>
              <h2 className="service-box-info-title">
                {services_data[2].title}
              </h2>
              <p className="service-box-text">{services_data[2].text}</p>
            </div>
          </Link>
        </div>
        <div className="service-box">
          <Link className="service-box-link" to={services_data[3].path}>
            <div className="service-box-info">
              <h2 className="service-icon">
                <FaTools />
              </h2>
              <h2 className="service-box-info-title">
                {services_data[3].title}
              </h2>
              <p className="service-box-text">{services_data[3].text}</p>
            </div>
          </Link>
        </div>
        <div className="service-box">
          <Link className="service-box-link" to={services_data[4].path}>
            <div className="service-box-info">
              <h2 className="service-icon">
                <SiOpencollective />
              </h2>
              <h2 className="service-box-info-title">
                {services_data[4].title}
              </h2>
              <p className="service-box-text">{services_data[4].text}</p>
            </div>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default Service;
