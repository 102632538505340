import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
// Import Swiper styles
import "swiper/css";
import simg1 from "../assets/slider-img1.jpg";
import simg2 from "../assets/slider-img2.jpg";
import simg3 from "../assets/slider-img3.jpg";
import simg4 from "../assets/slider-img4.jpg";
import simg5 from "../assets/slider-img5.jpg";

const WhyChooseUsSlider = () => {
  return (
    <>
      <div className="WhyChooseUsSlider-main">
        <div className="WhyChooseUsSlider-col1">
          <h4>WHY CHOOSE US</h4>
          <h2>
            Save your time with <br /> a lot of features
          </h2>
          {/* <img className="WhyChooseUsSlider-col1-img" src={background} alt="" /> */}
        </div>

        <div className="WhyChooseUsSlider-col2">
          <Swiper
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            spaceBetween={40}
            slidesPerView={3}
            modules={[Autoplay]}
          >
            <SwiperSlide>
              <div>
                <img src={simg1} className="WhyChooseUsSlider-img" alt="" />{" "}
                <br />
                Standard Code
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={simg2} className="WhyChooseUsSlider-img" alt="" />{" "}
                <br />
                Quick Support
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={simg3} className="WhyChooseUsSlider-img" alt="" />{" "}
                <br />
                Responsive Design
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={simg4} className="WhyChooseUsSlider-img" alt="" />{" "}
                <br />
                Fast Performance
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <img src={simg5} className="WhyChooseUsSlider-img" alt="" />{" "}
                <br />
                Fully Secured
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default WhyChooseUsSlider;
