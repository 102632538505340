import jetclean from '../assets/jetclean.png'
import dobi from '../assets/dobi.jpeg'
import jaystar from '../assets/jaystar.jpeg'
import newEraLogistics from '../assets/newEraLogistics.jpeg'
import AUSCompany from '../assets/AUSCompany.jpeg'
const people = [
    {
      id: 1,
      image:jetclean,
      name: 'Asem Raza',
      productUrl: 'https://jetclean.dk',
      product: 'Jet Clean',
      review:
      "Stech Solutions transformed our online presence. They deliver excellence.",
    },
    {
      id: 2,
      image:dobi,
      productUrl: 'https://dobi.dk',
      name: 'Hamza',
      product: 'Dobi',
      review:
      "Exceptional talent, responsive team – Stech Solutions delivers excellence.",
    },
    {
      id: 3,
      image:jaystar,
      name: 'Syed Taha',
      productUrl: 'https://jaystarco.com',
      product: 'Ecommerce Store',
      review:
      "They build relationships, not just websites – Stech Solutions shines.",
    },
    {
      id: 4,
      image:newEraLogistics,
      name: 'Usman',
      product: 'Business Profile',
      productUrl: 'https://neweralogistics.pk/',
      review:
      "Results-driven partnership – Stech Solutions excels in support.",
    },
    {
      id: 5,
      image:AUSCompany,
      name: 'Imran Bukhari',
      product: 'Company Registeration',
      productUrl: 'http://test.stechsole.com/',
      review:
      "Professional, top-notch IT solutions – Stech Solutions stands out.",
    },
  ];
  
  export default people;
  