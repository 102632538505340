import * as React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png"
const NavBar = () => {
  {{ console.log(window.dataLayer) }}

  return (
    <>
      <nav className="navbar navbar-expand-lg bg-transparent py-3 py-lg-4 " >
        <div className="container-fluid ">
          <Link style={{ color: "#00eeff65", textDecoration: "none" }} to="/">
            <img style={{width:"160px"}} src={logo} alt="" />
          </Link>
          {/* <Link className="navbar-brand" to="/">
            STECH
          </Link> */}
          <button
            className="navbar-toggler ml-auto custom-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              className="navbar-toggler-icon"
            ></span>
          </button>
          <div
            className="collapse navbar-collapse navbar-style"
            id="navbarTogglerDemo02"
            style={{justifyContent:"end"}}
          >
            <ul className="navbar-nav me-4 mb-2 mb-lg-0 navbar-style" >
              <li className="nav-item">
                <Link
                  className="nav-link navlink-style " style={{justifyContent:"end",display:"flex", marginRight:"6%"}}
                  aria-current="page"
                  to="/home"
                >
                  HOME
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/aboutus"
                >
                  ABOUTUS
                </Link>
              </li> */}
              <li className="nav-item">
                <Link style={{justifyContent:"end",display:"flex", marginRight:"6%"}}
                  className="nav-link navlink-style"
                  to="/services"
                >
                  SERVICES
                </Link>
              </li>
              <li className="nav-item">
                <Link style={{justifyContent:"end",display:"flex", marginRight:"6%"}}
                  className="nav-link navlink-style"
                  to="/projects"
                >
                  PROJECTS
                </Link>
              </li>
              <li className="nav-item">
                <Link style={{justifyContent:"end",display:"flex", marginRight:"6%"}}
                  className="nav-link navlink-style"
                  to="/contact"
                >
                  CONTACT
                </Link>
              </li>
            </ul>
            
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
