import React from "react";
import "./footersection.css";
import { Link } from "react-router-dom";
import {
  FaCcMastercard,
  FaCcPaypal,
  FaCcStripe,
  FaCcVisa,
  FaEnvelope,
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaMapMarker,
  FaPhone,
  FaGithubSquare,
  FaWhatsappSquare
} from "react-icons/fa";


function FooterSection(props) {
  return (
    <div>
      <footer className="section">
        {/* <img
          className="bg-image"
          src={image}
          alt=""
        /> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single-box">
                <p>
                  We acceopt all major credit cards <br></br>for fast and easy
                  payment.
                </p>
                <div className="card-area">
                  <i>
                    <Link>
                      <FaCcVisa size={40} />
                    </Link>
                  </i>
                  <i>
                    <Link>
                      <FaCcPaypal size={40} />
                    </Link>
                  </i>
                  <i>
                    <Link>
                      <FaCcMastercard size={40} />
                    </Link>
                  </i>
                  <i>
                    <Link>
                      <FaCcStripe size={40} />
                    </Link>
                  </i>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-box">
                <h2>Services</h2>
                <ul>
                  <li>
                    <span >Web Development</span>
                  </li>
                  <li>
                    <span >Search Engine Optimization</span>
                  </li>
                  <li>
                    <span >Social Media Marketing</span>
                  </li>
                  <li>
                    <span >Graphic Designing</span>
                  </li>
                  <li>
                    <span >Content Writing</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-box">
                <h2>See More</h2>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/aboutus">AboutUs</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="#">Blogs</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single-box">
                <ul className="contact-list">
                  <li className="list-item">
                    <FaMapMarker />
                    <span
                      className="contact-text place"
                      title="office location pinned."
                    >
                      Lahore, Pakistan
                    </span>
                  </li>

                  <li className="list-item">
                    <FaPhone />
                    <span className="contact-text phone">
                      <span title="Give me a call">+92 335 1757291</span>
                    </span>
                  </li>

                  <li className="list-item">
                    <FaEnvelope />
                    <span className="contact-text gmail">
                      <span title="Send me an email">info@stechsole.com</span>
                    </span>
                  </li>
                </ul>
                <h3>Social PlatForms</h3>
                <div className="social-app">
                  <a href="https://www.linkedin.com/company/stechsole/" target="_blank">
                    <FaLinkedin size={40} />
                  </a>
                  <i>
                    <a href="https://www.facebook.com/profile.php?id=100090017744249" target="_blank">
                      <FaFacebookSquare size={40} />
                    </a>
                  </i>
                  <i>
                  <a href="https://github.com/stech4588" target="_blank">
                      <FaGithubSquare size={40} />
                    </a>
                  </i>
                  <i>
                    <a href="https://api.whatsapp.com/send?phone=03351757297" target="_blank">
                      <FaWhatsappSquare size={40}  />
                    </a>
                  </i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default FooterSection;
