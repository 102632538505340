import React from "react";
import { Link } from "react-router-dom";
import { AiFillPhone } from "react-icons/ai";
import bg from "../assets/servicescard-bg.jpg";
const ContactUs2 = () => {
  return (
    <div className="contactus2">
      <img className="contactus2-section-bg" src={bg} alt="" />
      <div className="contactus2-row">
        <div className="contactus2-col-1">
          <h2>
            Obtain futher information by making contact with our Experienced
            IT Staff.
          </h2> <br />
          <h4>
            Tailored IT solutions that perfectly match the needs of our<br />
            clients.
          </h4>
        </div>
        <div className="contactus2-col-2">
          <h1 style={{ marginBlockStart: "0px" }}>
            {" "}
            <AiFillPhone />
          </h1>
          <h1>+92 335 1757291</h1>
          <Link to="/contact" className="contactus2-button">
            Contact Us Today
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContactUs2;
