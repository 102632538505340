import React from "react";
import card1 from "../assets/Servicescards (1).jfif";
import card2 from "../assets/Servicescards (2).jfif";
import card3 from "../assets/Servicescards (3).jfif";
import card4 from "../assets/Servicescards (4).png";
import { MdDeveloperBoard } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";
import { SiLaravel } from "react-icons/si";
import { BiLineChart } from "react-icons/bi";
const ServicesCards = () => {
  return (
    <>
      <div className="ServicesCard-main">
        <br/><h1>OUR SERVICES</h1>
        <h2>We offer all Professional IT Services</h2><br />
        <div className="ServicesCard-row">
          <div className="ServicesCard-col">
            <img className="ServicesCard-col-img " src={card1} alt="" />
            <div className="details">
              <div className="icon">
                <MdDeveloperBoard />
              </div>
              <h5>Software Development</h5>
              <p>
                Our software development team is a bundle of Seasoned Experts with
                Specialization in Web App development.
              </p>
            </div>
          </div>
          <div className="ServicesCard-col">
            <img className="ServicesCard-col-img" src={card2} alt="" />
            <div className="details">
              <div className="icon">
                <BiLineChart />
              </div>
              <h5>IT Management</h5>
              <p>
                Designing custom IT solutions & software for the web-based
                industry.
              </p>
            </div>
          </div>
          <div className="ServicesCard-col col-3">
            <img className="ServicesCard-col-img" src={card3} alt="" />
            <div className="details">
              <div className="icon">
                <SiLaravel />
              </div>
              <h5>Laravel Projects</h5>
              <p>
                We provide you easily scalable Apps from Websites to Micro
                Services. We enable your customized solution.{" "}
              </p>
            </div>
          </div>
          <div className="ServicesCard-col col-4">
            <img className="ServicesCard-col-img" src={card4} alt="" />
            <div className="details">
              <div className="icon">
                <AiOutlineCloudServer />
              </div>
              <h5>Cloud Services</h5>
              <p>
                Our Cloud storage services are meant for storing crical business
                data. Every media is compatible with Cloud.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesCards;
