import React, { useState, useRef } from "react";
// import { addUser } from "../../Service/Api";
import FooterSection from "./FooterSection";
import "./contact.css";
import bg from "../../assets/contactUs-Page-bg.jpg"
import emailjs from "@emailjs/browser";

const initialValue = {
  name: "",
  email: "",
  subject: "",
  message: "",
};
const ContactPage = () => {
  const [user, setUser] = useState(initialValue);
  const { user_name, user_email, subject, message } = user;

  const onValueChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    console.log(user);
  };
  const form = useRef();

  const handleSubmit = async (e) => {
    // await addUser(user);

    e.preventDefault();

    emailjs
      .sendForm(
        "service_q8kemva",
        "template_38t6kpt",
        form.current,
        // "mRB0fNd7KGPsKmnKr"
        "5nKo5y1sZXI-TcCGG"
      )
      .then(
        (result) => {
          console.log(result.text);
          // console.log(e.target.reset());
        },
        (error) => {
          console.log(error.text);
        }
      );

    alert("Your Message is sent to admin.");
  };

  return (
    <><div className="contactheading" style={{zIndex:"-1"}}>
    <h1>Contact</h1>
    <div className="contact-row">
    <h5>Home </h5> <p>{'>'}</p> <h5> Contact</h5>

    <hr className="vr-glow-line2"/>
    </div>
  </div>
      <section className="section" style={{zIndex:"12"}}>
      
        <div>
          <iframe
            className="my_map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3399.4414424483393!2d74.4166647!3d31.566940300000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190f0cddfefa15%3A0x82458296d42c5926!2sSTechSole!5e0!3m2!1sen!2s!4v1675708925868!5m2!1sen!2s"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="maplocation"
          ></iframe>
        </div>
        <img
          className="background_image"
          src={bg}
          alt=""
        />
        <div className="Material-contact-section section-padding section-dark">
          <div className="container">
            <div className="row">
              <div className="col-md-6 contact-section"><hr className="hr-glow-line"/>
                <hr className="vr-glow-line"/>
                <h3>
                  To Make Request For Further<br></br>
                  Information, Contact Us Via Our <br></br>
                  Social Channels.
                </h3>
                <h6>
                  We just need a couple of hours!<br></br>
                  No more than 2 working days since receiving your issuce
                  <br></br>
                  ticket.
                </h6>
              </div>
              <div className="col-md-6 from-section">
                <h1 className="section-title">Get Your Quotation</h1>
                <form  ref={form}
                  // className="shake"
                  // role="form"
                  // method="post"
                  // id="contactForm"
                  // name="contact-form"
                  // data-toggle="validator"
                  onSubmit={handleSubmit}
                >
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="name"
                      type="text"
                      name="name"
                      value={user_name}
                      placeholder="Name"
                      required
                      onChange={(e) => onValueChange(e)}
                      data-error="Please enter your name"
                    />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="email"
                      type="email"
                      name="from_name"
                      value={user_email}
                      placeholder="Email"
                      required
                      onChange={(e) => onValueChange(e)}
                      data-error="Please enter your Email"
                    />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      id="subject"
                      type="text"
                      name="subject"
                      value={subject}
                      placeholder="Subject"
                      required
                      onChange={(e) => onValueChange(e)}
                      data-error="Please enter your message subject"
                    />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows={5}
                      id="message"
                      name="message"
                      value={message}
                      placeholder="Message"
                      required
                      onChange={(e) => onValueChange(e)}
                      data-error="Write your message"
                    />
                    <div className="help-block with-errors" />
                  </div>
                  <div className="form-submit mt-4">
                    <button
                      className="btn btn-outline-dark"
                      style={{ color: "#fff", marginBottom: "1%", borderBlockColor: "#fff" }}
                      id="submit"
                      type="button"
                      // value="SEND"
                      onClick={(e) => handleSubmit(e)}
                    >
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterSection />
    </>
  );
};

export default ContactPage;
