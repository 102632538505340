import React from "react";
import ContactUs2 from "../../components/ContactUs2";
import PortfolioMain from "../../components/PortfolioMain";
import Footer from "../../components/Footer";


const Portfolio = () => {
  return (
    <>
      <PortfolioMain/>
      <ContactUs2/>
      <Footer/>
      {/* <ThreeAngledDivs/> */}
      
    </>
  );
};

export default Portfolio;
