import React from "react";

import Testimonials from "../../components/Testimonials";
import HeroSection from "../../components/HeroSection";
import Footer from "../../components/Footer";
import Service from "../../components/Service";
import CardsAnimation from "../../components/CardsAnimation";
import ContactUs from "../../components/ContactUs";
import WorkFlow from "../../components/WorkFlow";
import "./Home.css";
import {useEffect} from "react";

import ReactGA from "react-ga";
const Home = () => {
  // useEffect(() =>{
  //   ReactGA.pageview(window.location.pathname);
  // }, []);
  // ReactGA.event({
  //   action:"test action",
  //   label:"test label",
  // })
  useEffect(() => {
    ReactGA.initialize('G-249CC9X5GE');
    ReactGA.pageview(window.location.pathname);
    ReactGA.event({
      category: 'Test',
      action: 'test action',
      label: 'test label',
    });
  }, []);
  return (
    <>
      <HeroSection />
      <Service />
      <WorkFlow />
      <CardsAnimation />
      <Testimonials />
      <ContactUs />
      <Footer />
    </>
  );
};

export default Home;
