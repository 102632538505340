import React from "react";
import "../pages/Home/Home.css";
import Particle from "./Particle";
const HeroSection = () => {
  return (
    <>
      <Particle />
      <div className="hero-container">
        <div className="box">
          We are
          <div className="word">
            <span>Innovators</span>
            <span>Designers</span>
            <span>Developers</span>
            <span>Experts</span>
            <span>STECH</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroSection;
