import React from "react";
import "../pages/Services/Services.css";
import "../styles/ServicesPageHeroSection.scss";
import reactImg from "../assets/reactImg.png";
import laravelImg from "../assets/laravel-icon.png";
import bootstrapImg from "../assets/bootstrap-logo.png";

const ServicesPageHeroSection = () => {
  return (
    <>
      <div className="ServicesPageHeroSection-h1">
        <div className="ServicesPageHeroSection">
          <h1>Create Fast & Powerful</h1>
          <p>Tech Solutions for </p>
          <span>
            Let us give your ideas a shape<br />
            We manifest reality where there were only dreams.
          </span>
          <span className="technology-icons">
            <img className="technology-icons-2" src={laravelImg} alt="" />
            <img className="technology-icons-1" src={reactImg} alt="" />
            <img className="technology-icons-3" src={bootstrapImg} alt="" />
          </span>
        </div>
      </div>
    </>
  );
};

export default ServicesPageHeroSection;
