import React from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";
const Footer = () => {
  return (
    <div className="footer">
      <p style={{paddingLeft:'0px'}}>
        {" "}
        <BsFillTelephoneFill /> +92 335 1757291
      </p>
      <p>
        {" "}
        <MdOutlineMailOutline /> info@stechsole.com
      </p>
      <p>© ALL OF THE RIGHTS RESERVED</p>
    </div>
  );
};

export default Footer;
