import jetclean from '../assets/jetclean.png'
import jetclean1 from '../assets/jetclean1.jpeg'
import jetclean2 from '../assets/jetclean2.jpeg'
import dobi from '../assets/dobi.jpeg'
import dobi1 from '../assets/dobi1.jpeg'
import dobi2 from '../assets/dobi2.jpeg'
import jaystar from '../assets/jaystar.jpeg'
import jaystar1 from '../assets/jaystar1.jpeg'
import newEraLogistics from '../assets/newEraLogistics.jpeg'
import newEraLogistics1 from '../assets/newEraLogistics1.jpeg'
import newEraLogistics2 from '../assets/newEraLogistics2.jpeg'
import AUSCompany from '../assets/AUSCompany.jpeg'
import AUSCompany1 from '../assets/AUSCompany1.jpeg'
import AUSCompany2 from '../assets/AUSCompany2.jpeg'
import hrm from '../assets/hrm.jpeg'
import hrm1 from '../assets/hrm1.jpeg'

const services = [
    {
      id: 1,
      title: 'Jet Clean',
      path:'https://jetclean.dk',
      images: [ jetclean, jetclean1, jetclean]
    },
    {
      id: 2,
      title: 'Jay Star',
      path:"https://jaystarco.com",
       images: [ jaystar, jaystar1, jetclean2]
    },
    {
      id: 3,
      title: 'Dobi',
      path:'https://dobi.dk',
      images: [ dobi, dobi1, dobi2]
    },
    {
      id: 4,
      title: 'New Era Logistics ',
      path:'https://neweralogistics.pk/',
      images: [ newEraLogistics, newEraLogistics1, newEraLogistics2]
    },
    {
      id: 5,
      title: 'AUS Company',
      path:'http://test.stechsole.com/',
       images: [ AUSCompany, AUSCompany1, AUSCompany2]
    },
    {
      id: 6,
      title: 'HRM',
      path:'https://dev-hrm.stechsole.com/',
       images: [ hrm, hrm1, jetclean]
    }
    
  ];
  
  export default services;